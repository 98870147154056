import React from 'react';

import Image from '../elements/Image';

const ImageBlock = ({ image }) => {
  return (
    <div className="w-full h-auto mx-auto rounded-[10px] overflow-hidden safari-overflow">
      <Image image={image} className="w-full h-auto" />
    </div>
  );
};

export default ImageBlock;
