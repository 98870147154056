import React from 'react';

import Text from './Text';
import ImageBlock from './ImageBlock';
import Points from './Points';
import Quote from './Quote';

import { ChevronDown } from '../elements/Icon';

const ArticleRenderer = ({ content, prefix, smallGap }) => {
  return (
    <section>
      <div className={`container ${smallGap ? 'space-y-10 md:space-y-16' : 'space-y-12 md:space-y-[120px]'}`}>
        {content?.map((layout, i) => {
          const layoutName = layout.fieldGroupName.replace(prefix, '');
          switch (layoutName) {
            case 'Text':
              return <Text key={i} {...layout} smallGap={smallGap} />;
              break;
            case 'Image':
              return <ImageBlock key={i} {...layout} />;
              break;
            case 'Quote':
              return <Quote key={i} {...layout} />;
              break;
            case 'Bullets':
              return <Points key={i} {...layout} />;
              break;
            default:
              console.log('IMPLEMENT ', layoutName, layout);
          }
          return null;
        })}
        {!smallGap && (
          <div className="text-center !mb-[42px]">
            <a href="#top" className="mx-auto max-w-[120px] flex items-center space-x-2 text-black font-medium t-18">
              <div className="rotate-[180deg]">
                <ChevronDown />
              </div>
              <span>Back to top</span>
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default ArticleRenderer;
