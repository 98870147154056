import React from "react";

const Quote = ({
  quote,
  addContext,
  by,
}) => {

  return(
    <div className="max-w-[854px] mx-auto text-center"> 
    <OpenQuote className={'mb-4 md:mb-0'}/>
      <p className="max-w-[794px] mx-auto font-heading t-32 font-bold leading-[1.43]" dangerouslySetInnerHTML={{__html: quote}}/>
      {addContext &&
        <div className="mt-6 md:mt-8">
          <p className="font-bold t-16">{by.name}</p>
          <p className=" t-16">{by.role}</p>
        </div>
      }
      <CloseQuote className={'ml-auto mr-0 mt-4 md:mt-0'}/>
    </div>
  );
};

export const OpenQuote = ({className}) => (
  <svg className={`${className}`} width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.34 18.02C3.3 19.22 4.98 20.42 5.94 20.42C6.72 20.42 7.74 19.82 9 18.56C10.26 17.3 10.92 16.22 10.92 15.44C10.92 14.72 10.2 13.64 8.82 12.14C7.62 10.88 7.02 9.74 7.02 8.78C7.02 7.7 7.62 6.5 8.88 5.12C9.9 3.92 11.7 2.78 11.76 2.72C12 2.48 12.12 2.18 12.12 1.88C12.12 1.1 11.4 0.439999 10.62 0.439999C9.42 0.439999 6.96 2.24 4.86 4.7C1.98 7.94 0.54 10.88 0.54 13.52C0.54 15.02 1.14 16.52 2.34 18.02ZM16.26 18.02C17.22 19.22 18.9 20.42 19.86 20.42C20.64 20.42 21.66 19.82 22.92 18.56C24.18 17.3 24.84 16.22 24.84 15.44C24.84 14.72 24.12 13.64 22.74 12.14C21.54 10.88 20.94 9.74 20.94 8.78C20.94 7.7 21.54 6.5 22.8 5.12C23.82 3.92 25.62 2.78 25.68 2.72C25.92 2.48 26.04 2.18 26.04 1.88C26.04 1.1 25.32 0.439999 24.54 0.439999C23.34 0.439999 20.88 2.24 18.78 4.7C15.9 7.94 14.46 10.88 14.46 13.52C14.46 15.02 15.06 16.52 16.26 18.02Z" fill="#12C0C0"/>
  </svg>
);

export const CloseQuote = ({className}) => (
  <svg className={`${className}`} width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.66 18.02C23.7 19.22 22.02 20.42 21.06 20.42C20.28 20.42 19.26 19.82 18 18.56C16.74 17.3 16.08 16.22 16.08 15.44C16.08 14.72 16.8 13.64 18.18 12.14C19.38 10.88 19.98 9.74 19.98 8.78C19.98 7.7 19.38 6.5 18.12 5.12C17.1 3.92 15.3 2.78 15.24 2.72C15 2.48 14.88 2.18 14.88 1.88C14.88 1.1 15.6 0.439999 16.38 0.439999C17.58 0.439999 20.04 2.24 22.14 4.7C25.02 7.94 26.46 10.88 26.46 13.52C26.46 15.02 25.86 16.52 24.66 18.02ZM10.74 18.02C9.78 19.22 8.1 20.42 7.14 20.42C6.36 20.42 5.34 19.82 4.08 18.56C2.82 17.3 2.16 16.22 2.16 15.44C2.16 14.72 2.88 13.64 4.26 12.14C5.46 10.88 6.06 9.74 6.06 8.78C6.06 7.7 5.46 6.5 4.2 5.12C3.18 3.92 1.38 2.78 1.32 2.72C1.08 2.48 0.959998 2.18 0.959998 1.88C0.959998 1.1 1.68 0.439999 2.46 0.439999C3.66 0.439999 6.12 2.24 8.22 4.7C11.1 7.94 12.54 10.88 12.54 13.52C12.54 15.02 11.94 16.52 10.74 18.02Z" fill="#12C0C0"/>
  </svg>
);

export default Quote;