import React from 'react';

const Text = ({ heading, text, smallGap }) => {
  return (
    <div className="max-w-[780px] mx-auto">
      <h2 className="t-42 font-medium  mb-6 md:mb-[40px]">{heading}</h2>
      <div className={`post-prose ${smallGap ? 'is-smaller' : ''}`} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default Text;
